<template>
    <b-row>
        <b-col cols="12">
            <div
                class="d-sm-flex justify-content-between align-items-center mb-4"
            >
                <feather
                    type="arrow-left-circle"
                    v-b-popover.hover.top="'Presione esc'"
                    role="button"
                    class="text-secondary"
                    size="2rem"
                    @click="back"
                ></feather>
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        v-b-popover.hover.top="'Presione esc'"
                        size="md"
                        class="mr-4"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        class="d-flex align-items-center"
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col
            cols="12"
            lg="12"
            class="d-flex align-items-stretch"
            style="min-height: 70vh"
        >
            <b-card class="mb-4 w-100">
                <div>
                    <b-form class="p-5">
                        <div class="d-flex">
                            <div>
                                <h3 class="mb-2">Datos de la sede</h3>
                                <div class="text-gray mb-5">
                                    Por favor registre los datos de su sede para
                                    continuar
                                </div>
                            </div>
                        </div>
                        <b-row v-hotkey="keymap">
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="codigo"
                                        >Código
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="codigo"
                                        type="text"
                                        placeholder="Ej: 001"
                                        @focus="onFocused"
                                        v-model="$v.sede.codigo.$model"
                                        :state="validateState('codigo')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <!-- @keyup.enter="
                                            goToNextField('descripcion')
                                        "
                                        @keyup.tab="
                                            goToNextField('descripcion')
                                        " -->
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.sede.codigo.$anyError"
                                    >
                                        ({{ $v.sede.codigo.$model.length }} /
                                        {{
                                            $v.sede.codigo.$params.maxLength
                                                .max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.sede.codigo.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.sede.codigo.$params.maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.sede.codigo.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="8" lg="8" xl="8">
                                <b-form-group>
                                    <label for="descripcion"
                                        >Descripción
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="descripcion"
                                        type="text"
                                        placeholder="Ej: Nombre del banco"
                                        @focus="onFocused"
                                        v-model="$v.sede.descripcion.$model"
                                        :state="validateState('descripcion')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <!-- @keyup.enter="
                                        goToNextField('municipio_id')
                                    "
                                    @keyup.tab="
                                        goToNextField('municipio_id')
                                    " -->
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.sede.descripcion.$anyError"
                                    >
                                        ({{ $v.sede.descripcion.$model.length }}
                                        /
                                        {{
                                            $v.sede.descripcion.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.sede.descripcion.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.sede.descripcion.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.sede.descripcion.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="direccion"
                                        >Dirección
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        autocomplete="null"
                                        id="direccion"
                                        type="text"
                                        placeholder="Ej: calle 27 #38-43"
                                        v-model="$v.sede.direccion.$model"
                                        :state="validateState('direccion')"
                                        :disabled="read_only"
                                    ></b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.sede.direccion.$anyError"
                                    >
                                        ({{ $v.sede.direccion.$model.length }} /
                                        {{
                                            $v.sede.direccion.$params.maxLength
                                                .max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.sede.direccion.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.sede.direccion.$params.maxLength
                                                .max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.sede.direccion.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="municipio_id"
                                        >Ciudad
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="municipio_id"
                                        :items="municipios"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        v-model="$v.sede.municipio_id.$model"
                                        :state="validateState('municipio_id')"
                                        :disabled="read_only"
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.sede.municipio_id.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" sm="4">
                                <label for="bodega_id"
                                    >Bodega
                                    <small style="color: red">*</small></label
                                >
                                <jautocomplete
                                    id="bodega_id"
                                    :items="bodegas"
                                    item-value="id"
                                    item-text="descripcion"
                                    open-on-focus
                                    v-model="$v.sede.bodega_id.$model"
                                    :disabled="read_only"
                                    :state="validateState('bodega_id')"
                                >
                                    <template v-slot:option="{ item }">
                                        <span
                                            >{{ item.codigo }} -
                                            {{ item.descripcion }}</span
                                        >
                                    </template>
                                </jautocomplete>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.sede.bodega_id.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="mt-md-4">
                            <b-col cols="12" sm="6">
                                <label for="lote_inventario_id"
                                    >Lote inventario
                                    <small style="color: red">*</small>
                                </label>
                                <jautocomplete
                                    id="lote_inventario_id"
                                    :items="lotesInventario"
                                    item-value="id"
                                    item-text="nombre"
                                    open-on-focus
                                    v-model="$v.sede.lote_inventario_id.$model"
                                    :disabled="read_only"
                                    :state="validateState('lote_inventario_id')"
                                >
                                    <template v-slot:option="{ item }">
                                        <span
                                            >{{ item.codigo }} -
                                            {{ item.nombre }}</span
                                        >
                                    </template>
                                </jautocomplete>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.sede.lote_inventario_id.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-col>

                            <b-col cols="12" sm="6">
                                <label for="centro_costo_id"
                                    >Centro de costo
                                    <small style="color: red">*</small>
                                </label>
                                <jautocomplete
                                    id="centro_costo_id"
                                    :items="centrosCosto"
                                    item-value="id"
                                    item-text="descripcion"
                                    open-on-focus
                                    v-model="$v.sede.centro_costo_id.$model"
                                    :disabled="read_only"
                                    :state="validateState('centro_costo_id')"
                                >
                                    <template v-slot:option="{ item }">
                                        <span
                                            >{{ item.codigo }} -
                                            {{ item.descripcion }}</span
                                        >
                                    </template>
                                </jautocomplete>
                                <div
                                    class="invalid-field mt-1"
                                    v-if="$v.sede.centro_costo_id.$error"
                                >
                                    Campo requerido
                                </div>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
            </b-card>
        </b-col>
        <b-col cols="12">
            <div class="float-right d-flex flex-row">
                <b-button
                    variant="secondary"
                    :disabled="isLoading"
                    v-b-popover.hover.top="'Presione esc'"
                    size="md"
                    class="mr-4"
                    @click="back"
                >
                    Cancelar
                </b-button>

                <b-button
                    variant="primary"
                    :disabled="isLoading || read_only"
                    size="md"
                    v-b-popover.hover.top="
                        'Presione ctrl+shift+s o cmd+shift+s'
                    "
                    class="d-flex align-items-center"
                    @click="save"
                >
                    <feather
                        type="save"
                        size="1rem"
                        class="mr-2 text-blue-active"
                    />
                    <span
                        v-if="isLoading"
                        class="d-sm-flex align-items-center justify-content-center"
                    >
                        <b-spinner small></b-spinner>
                        <span class="ml-2">Guardando...</span>
                    </span>
                    <span v-else>Guardar</span>
                </b-button>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapGetters, mapMutations } from 'vuex'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import {
    removeListenerCommands,
    addListenerCommands,
    onFocused,
    goToNextField,
    compareObjects
} from '@/utils/others'

const { API } = services

const defaultSede = () =>
    JSON.parse(
        JSON.stringify({
            codigo: '',
            descripcion: '',
            direccion: '',
            empresa_id: 3,
            municipio_id: null,
            bodega_id: 0,
            lote_inventario_id: 0,
            centro_costo_id: '0'
        })
    )

export default {
    name: 'crearSede',
    mixins: [validationMixin],
    components: { jautocomplete },
    data: () => ({
        sedeCopy: defaultSede(),
        sede: defaultSede(),
        isLoading: false,
        isSaved: true,
        read_only: false,
        municipios: [],
        bodegas: [],
        lotesInventario: [],
        centrosCosto: []
    }),
    watch: {
        sede: {
            handler(newValue) {
                this.isSaved = compareObjects(this.sedeCopy, newValue)
            },
            deep: true
        }
    },
    mounted() {
        this.goToNextField('codigo')
        addListenerCommands(this)
        this.getParams()
        this.getBodegas()
        this.getLotesInventario()
        this.getCentrosCosto()
        this.getMunicipios(this.getUltimaEmpresa.pais.id)
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        sede: {
            codigo: {
                required,
                maxLength: maxLength(25)
            },
            descripcion: {
                required,
                maxLength: maxLength(150)
            },
            direccion: {
                required,
                maxLength: maxLength(150)
            },
            municipio_id: {
                required
            },
            bodega_id: {
                required
            },
            lote_inventario_id: {
                required
            },
            centro_costo_id: {
                required
            }
        }
    },
    methods: {
        onFocused,
        goToNextField,
        getMunicipios(pais_id) {
            API.POST({
                url: 'general/municipio/query',
                data: {
                    p_datajson: {
                        pais_id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.municipios = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getLotesInventario() {
            API.POST({
                url: 'administracion/inventario/lote-inventario/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.lotesInventario = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getCentrosCosto() {
            API.POST({
                url: 'contabilidad/centro-costo/all',
                data: {
                    empresaId: this.getUltimaEmpresa.id
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.centrosCosto = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getParams() {
            const { sede_id } = this.$route.params
            if (sede_id) {
                this.getSedeById(sede_id)
            }

            this.read_only = this.$route.meta.read_only
        },
        getSedeById(id) {
            // console.log('acá 2');
            API.POST({
                url: 'general/sede/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.sedeCopy = {
                            ...dato,
                            lote_inventario_id: dato.lote_inventario_id || 0,
                            centro_costo_id: dato.centro_costo_id || '0'
                        }
                        this.sede = {
                            ...dato,
                            lote_inventario_id: dato.lote_inventario_id || 0,
                            centro_costo_id: dato.centro_costo_id || '0'
                        }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getBodegas() {
            API.POST({
                url: 'administracion/inventario/bodega/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.bodegas = [...dato]
                        // console.log(this.bodegas)
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        save() {
            const valid = !this.$v.sede.$invalid
            if (valid) {
                this.isLoading = true
                this.sede.empresa_id = this.getUltimaEmpresa.id
                const p_opc = this.sede.id ? 'UPDATE' : 'INSERT'
                API.POST({
                    url: 'general/sede/crud',
                    data: {
                        p_datajson: {
                            ...this.sede
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            // this.empresa = defaultEmpresa()
                            this.setUltimaSede(dato)
                            this.back()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.sede.$touch()
            }
        },
        back() {
            this.$router.back()
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.sede[key]
            return $dirty ? !$error : null
        },
        ...mapMutations('sedes', ['setUltimaSede'])
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'command+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'ctrl+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 5 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('usuario', ['getUserData']),
        ...mapGetters('empresas', ['getUltimaEmpresa'])
    }
}
</script>

<style>
/* .form-control {
    background-color: #ffffff;
    color: #000000;
    font-weight: 500;
}

.form-control:focus {
    border-color: #00a2ff;
    border-width: 0.15rem;
    -webkit-box-shadow: 11px 10px 18px -12px rgba(0, 0, 0, 0.49);
    -moz-box-shadow: 11px 10px 18px -12px rgba(0, 0, 0, 0.49);
    box-shadow: 11px 10px 18px -12px rgba(0, 0, 0, 0.49);
}

.form-control:disabled {
    background-color: #e5e8ee;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: #00a2ff;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="UTF-8"%3F><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="512px" height="512px" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path style="opacity:0.998" fill="%232196f3" d="M 232.5,-0.5 C 247.833,-0.5 263.167,-0.5 278.5,-0.5C 378.271,12.4486 449.438,64.4486 492,155.5C 502.136,180.379 508.636,206.046 511.5,232.5C 511.5,247.833 511.5,263.167 511.5,278.5C 498.551,378.271 446.551,449.438 355.5,492C 330.622,502.136 304.955,508.636 278.5,511.5C 263.167,511.5 247.833,511.5 232.5,511.5C 132.727,498.549 61.5605,446.549 19,355.5C 8.86392,330.621 2.36392,304.954 -0.5,278.5C -0.5,263.167 -0.5,247.833 -0.5,232.5C 12.4486,132.729 64.4486,61.5622 155.5,19C 180.379,8.86392 206.046,2.36392 232.5,-0.5 Z"/></g><g><path style="opacity:1" fill="%23f9f9f9" d="M 365.5,164.5 C 383.331,164.164 391.831,172.83 391,190.5C 390.241,193.785 388.908,196.785 387,199.5C 339.5,247 292,294.5 244.5,342C 235.803,347.913 227.136,347.913 218.5,342C 194,317.5 169.5,293 145,268.5C 137.235,253.795 140.735,242.628 155.5,235C 162.215,233.752 168.548,234.752 174.5,238C 193.347,257.014 212.347,275.847 231.5,294.5C 273.653,252.847 315.653,211.014 357.5,169C 360.099,167.205 362.766,165.705 365.5,164.5 Z"/></g></svg>');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    border-color: #00a2ff;
    -webkit-box-shadow: 11px 10px 18px -12px rgba(0, 0, 0, 0.49);
    -moz-box-shadow: 11px 10px 18px -12px rgba(0, 0, 0, 0.49);
    box-shadow: 11px 10px 18px -12px rgba(0, 0, 0, 0.49);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    border-color: #fb8c00;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    -webkit-box-shadow: 11px 10px 18px -12px rgba(0, 0, 0, 0.49);
    -moz-box-shadow: 11px 10px 18px -12px rgba(0, 0, 0, 0.49);
    box-shadow: 11px 10px 18px -12px rgba(0, 0, 0, 0.49);
}*/
</style>
